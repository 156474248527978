<template>
  <v-navigation-drawer
    fixed
    app
    dark
    v-model="drawer"
    :mini-variant.sync="mini"
    stateless
    hide-overlay
    touchless
    width="250"
  >
    <!--<v-toolbar flat class="transparent" color="primary">-->
    <v-toolbar flat class="transparent">
      <v-list class="pa-0">
        <v-list-tile avatar class="text-xs-center">
          <v-list-tile-avatar>
            <!-- <img :src="`https://ui-avatars.com/api/?name=Hunno&size=36`" /> -->
            <img :src="require('./../assets/logo-menu.png')" width="48" height="48" />
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title>Safir Coorporate</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-btn icon @click.stop="mini = !mini">
              <v-icon>chevron_left</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-toolbar>

    <v-list class="pt-0" dense>
      <!-- <v-divider></v-divider> -->
      <div v-for="item in items" :key="item.text">
        <v-list-tile v-if="((currentUser.tipo !== 'A' && currentUser.tipo !== 'C') || item.adm)">
          <v-list-tile-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <router-link :to="item.path" active-class="primary-color" exact>
              <span>{{ item.text }}</span>
            </router-link>
          </v-list-tile-content>
        </v-list-tile>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MenuMini',
  data () {
    return {
      drawer: true,
      mini: true,
      items: [
        { icon: 'assessment', text: 'Dashboard', path: '/dashboard', adm: true },
        { icon: 'search', text: 'Consultas', path: '/search', adm: true },
        { icon: 'shopping_cart', text: 'Vendas', path: '/vendas', adm: false },
      ],
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
}
</script>
<style scoped>
a {
  text-decoration: none;
  /*color: rgba(0, 0, 0, 0.87);*/
  color: white;
}
/*blue--text*/
.primary-color {
  color: #006d7c;
}
.v-navigation-drawer {
  background-color: #0f2032;
}
</style>